<template>
  <section class="crew">
    <h1>{{ $t("donate.donate") }}</h1>
    <div class="wrap">
      <p>{{ $t("donate.desc") }}</p>
      <header>
        <h4>{{ $t("donate.types.title") }}</h4>
      </header>
      <ul>
        <li>{{ $t("donate.types.t1") }}</li>
        <li>{{ $t("donate.types.t2") }}</li>
        <li>{{ $t("donate.types.t3") }}</li>
        <li>{{ $t("donate.types.t4") }}</li>
        <li>{{ $t("donate.types.t5") }}</li>
      </ul>
      <div class="donate-btn">
        <a
          class="button"
          href="https://www.afi.com/support/?Op=donate&don=449"
          target="_blank"
          >{{ $t("donate.click") }}</a
        >
      </div>
    </div>
  </section>
</template>
  
  <script>
export default {
  data() {
    return {
      lang: "en",
    };
  },
};
</script>
  
  <style lang="scss" scoped>
@import url("../../base.scss");
.crew {
  background-image: url("../../assets/backgrounds/bg2.jpeg");
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  box-shadow: inset 0 -8rem 8rem 0 rgba(0, 0, 0, 0.8);
  z-index: 1;
  .wrap {
    font-size: 1.5rem;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
  }
  h1 {
    color: white;
    font-size: 7rem;
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 1rem;
    font-family: Proxima;
    line-height: 1;
  }
  h4 {
    color: white;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 1rem;
    line-height: 1;
    text-shadow: none;
    font-weight: bold;
    padding-top: 4rem;
    padding-bottom: 2rem;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 1rem;
    }
  }
  .donate-btn {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    a.button {
      border: 4px solid rgba(255, 255, 255, 0.7);
      color: rgba(255, 255, 255, 0.7);
      background: none;
      padding: 1rem;
      cursor: pointer;
      text-align: center;
      transition: all 0.3s;
      font-size: 1.5rem;
      &:hover,
      &:focus,
      &:active {
        border: 4px solid white;
        color: white;
        box-shadow: inset 15.5em 5px 5px 5px rgba(255, 255, 255, 0.3);
      }

      &:link,
      &:visited {
        text-decoration: none;
        text-transform: none;
        color: inherit;
      }
    }
  }
}
</style>