<template>
  <div class="item" style="back">
    <div :class="{ larger: member.larger }">{{ member.name }}</div>

    <div :class="{ larger: member.larger }">{{ member.surname }}</div>
    <div class="pic" :class="member.actorBg"></div>
    <p>{{ $t(member.description) }}</p>
  </div>
</template>

<script>
export default {
  props: ["member"],
  methods: {
    getUrl(pic) {
      return require(`@/assets/actors/${pic}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  max-width: 75%;
  margin: 0 auto;
  font-size: 1.5rem;
  div {
    font-weight: normal;
    &.larger {
      font-weight: bold;
      font-size: 150%;
      text-shadow: none;
    }
  }
  p {
    font-weight: normal;
    text-align: left;
    font-size: 1rem;
  }
}
.pic {
  height: auto;
  width: 15vw;
  height: 15vw;
  margin: 0 auto;
  background-size: 110%;
  background-repeat: no-repeat;
  margin-top: 1.3rem;
  background-position: top center;
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.3);
  &.herrera {
    background-image: url("../../assets/actors/herrera.jpg");
    background-size: 102%;
  }
  &.matyja {
    background-image: url("../../assets/actors/matyja.jpg");
  }
  &.sablinska {
    background-image: url("../../assets/actors/sablinska.jpg");
    background-size: 105%;
  }
  &.stanton {
    background-image: url("../../assets/actors/stanton.jpg");
  }
  &.sui {
    background-image: url("../../assets/actors/sui.jpg");
    background-size: 105%;
  }
}
@media screen and (max-width: 980px) {
  .item {
    max-width: 75%;
    .pic {
      width: 50vw;
      height: 50vw;
    }
    p {
      font-size: 1.5rem;
    }
  }
}
@media screen and (max-width: 680px) {
  .item {
    p {
      font-size: 1.5rem;
    }
  }
}
</style>