<template>
  <section class="movie">
    <div class="wrap">
      <h1>{{ $t("AFI.AFI") }}</h1>
      <div class="story">
        <div class="pic">
          <img src="../../assets/backgrounds/bigpic2.jpg" alt="story picture" />
        </div>
        <div>
          <p>{{ $t("AFI.about") }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.movie {
  background: black;
  background-size: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  box-shadow: inset 0 -8rem 8rem 0 rgba(0, 0, 0, 0.8);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 4rem 0;
  .story {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    div {
      max-width: 100%;
      img {
        width: 100%;
      }
    }
  }
  h1 {
    color: white;
    font-size: 6rem;
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 0rem;
    line-height: 1;
    font-weight: bold;
    padding-bottom: 3rem;
    font-family: Proxima;
  }
  p {
    text-align: justify;
    font-size: 2rem;
    line-height: 1;
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .movie {
    .story {
      flex-direction: column;
      div {
        max-width: 100%;
      }
    }
  }
}
@media screen and (max-width: 980px) {
  .movie {
    background-attachment: unset;
  }
}
@media screen and (max-width: 680px) {
  .movie {
    p {
      font-size: 1.5rem;
      max-width: 75%;
      margin: 0 auto;
    }
  }
}
</style>