<template>
  <IntroSection></IntroSection>
  <MovieSection></MovieSection>
  <ActorsSection></ActorsSection>
  <AFI></AFI>
  <Donate></Donate>
</template>

<script>
import IntroSection from "../components/intro/IntroSection.vue";
import MovieSection from "../components/movie/MovieSection.vue";
import AFI from "../components/movie/AFI.vue";
import ActorsSection from "../components/actors/ActorsSection.vue";
import Donate from "../components/actors/Donate.vue";

export default {
  name: "Home",
  components: {
    IntroSection,
    MovieSection,
    AFI,
    ActorsSection,
    Donate,
  },
};
</script>
