<template>
  <ul>
    <li v-for="member in members" :key="member.id" :class="member.actorBg">
      <CrewItem :member="member"></CrewItem>
    </li>
  </ul>
</template>
  
  <script>
import CrewItem from "./ActorItem.vue";

export default {
  data() {
    return {
      members: [
        {
          id: 0,
          name: "Piotr",
          surname: "Matyja",
          larger: true,
          description: "actors.matyja.description",
          actorBg: "matyja",
          pic: "matyja.jpg",
        },
        {
          id: 1,
          name: "Zofia",
          surname: "Sablinska",
          larger: true,
          description: "actors.sablinska.description",
          actorBg: "sablinska",
          pic: "sablinska.jpg",
        },
        {
          id: 2,
          name: "Sika",
          surname: "Stanton",
          larger: true,
          description: "actors.stanton.description",
          actorBg: "stanton",
          pic: "stanton.jpg",
        },
        {
          id: 3,
          name: "Jose",
          surname: "Herrera",
          larger: true,
          description: "actors.herrera.description",
          actorBg: "herrera",
          pic: "herrera.jpg",
        },
        {
          id: 4,
          name: "Xin",
          surname: "Sui",
          larger: true,
          description: "actors.sui.description",
          actorBg: "sui",
          pic: "sui.jpg",
        },
      ],
    };
  },
  components: { CrewItem },
};
</script>
  
  <style lang="scss" scoped>
ul {
  color: white;
  font-size: 2rem;
  margin-bottom: 6rem;
  font-weight: bold;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding-top: 6rem;
  flex-direction: row;
  flex-wrap: wrap;
  li {
    list-style: none;
    text-align: center;

    background-size: 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    width: 30%;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
    // height: 100%;
    // &.lin {
    //   background-image: url("../../assets/backgrounds/9.png");
    // }
    // &.skra {
    //   background-image: url("../../assets/backgrounds/5.png");
    // }
    // &.kub {
    //   background-image: url("../../assets/backgrounds/11.png");
    // }
    p {
      text-align: justify;
    }
  }
}
@media screen and (max-width: 980px) {
  ul {
    flex-wrap: wrap;
    li {
      background-attachment: unset;
      background-size: cover;
      width: 100%;
      padding: 2rem 0;
      &.kub {
        background-position: 25% center;
      }
    }
  }
}
</style>