<template>
  <section class="crew">
    <h1>{{ $t("actors.actors") }}</h1>
    <CrewList></CrewList>
  </section>
</template>
  
  <script>
import CrewList from "./ActorsList.vue";
export default {
  data() {
    return {
      lang: "en",
    };
  },
  components: { CrewList },
};
</script>
  
  <style lang="scss" scoped>
@import url("../../base.scss");
.crew {
  background-image: url("../../assets/backgrounds/mini1.jpg");
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  box-shadow: inset 0 -8rem 8rem 0 rgba(0, 0, 0, 0.8);
  z-index: 1;
  h1 {
    color: white;
    font-size: 7rem;
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 1rem;
    transform: scale(0.7, 1.3);
    line-height: 1;
    font-family: Proxima;
  }
}
</style>