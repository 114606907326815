<template>
  <section class="intro">
    <div class="afi">
      <img src="../../assets/backgrounds/afi.png" alt="AFI Logo" />
    </div>
    <div class="lang">
      <div class="ig snd">
        <a
          class="insta"
          href="https://instagram.com/theonlysonfilm?igshid=YmMyMTA2M2Y="
          ><Instagram></Instagram
        ></a>
      </div>
      <div class="active" @click="changeLang(inactiveLangs[0])">
        <span
          ><img
            :src="`https://flagcdn.com/w40/${inactiveLangs[0].flag}.png`"
            :srcset="`
              https://flagcdn.com/w80/${inactiveLangs[0].flag}.png 2x,`"
            width="40"
            :alt="inactiveLangs[0].country"
        /></span>
      </div>
    </div>
    <div class="wrap">
      <h1>{{ $t("title.upper") }} {{ $t("title.lower") }}</h1>
      <div class="jumbo">
        <p>An AFI Thesis Film</p>
        <a
          class="button"
          href="https://www.afi.com/support/?Op=donate&don=449"
          target="_blank"
          >{{ $t("donate.button") }}</a
        >
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Instagram from "./Instagram.vue";

export default {
  components: { Instagram },
  data() {
    return {
      activeLang: { id: 0, lang: "en", country: "England", flag: "gb" },
      langs: [
        {
          id: 0,
          lang: "pl",
          country: "Poland",
          flag: "pl",
        },
        { id: 1, lang: "en", country: "England", flag: "gb" },
      ],
      langsVisible: false,
    };
  },
  computed: {
    ...mapGetters(["getLocale"]),
    inactiveLangs() {
      return this.langs.filter((lang) => lang.lang !== this.activeLang.lang);
    },
  },
  methods: {
    ...mapActions(["setLang"]),
    changeLang(lang) {
      this.setLang(lang);
      this.initActiveLang(lang);
      this.toggleLangs();
    },
    initActiveLang(lang = this.getLocale) {
      this.activeLang = lang;
    },
    toggleLangs() {
      this.langsVisible = !this.langsVisible;
    },
  },
  mounted() {
    this.initActiveLang();
  },
};
</script>

<style lang="scss" scoped>
@import url("../../base.scss");
.afi {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 12rem;
  img {
    width: 100%;
  }
}
svg {
  fill: white;
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1rem;
  vector-effect: non-scaling-stroke;
}
.ig {
  position: absolute;
  color: white;
  text-align: center;
  top: 5%;
  right: 5%;
  &.snd {
    margin-top: 2rem;
  }
  .insta {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    fill: white;
    position: relative;
    transform: translateY(-120%);
    right: 105%;
  }
}
.lang {
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: right;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 10;

  .active {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .triangle {
    font-size: 0.8rem;
    text-transform: lowercase;
  }
}
.intro {
  background: url("../../assets/backgrounds/OPENER.jpg") top center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 -8rem 8rem 0 rgba(0, 0, 0, 0.8);
  padding-bottom: 10%;
  padding-top: 5%;
  height: 80vh;
  h1 {
    color: white;
    font-size: 7rem;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
    transform: scale(0.7, 1.3);
    line-height: 1;
    font-family: Canela;
  }
  .wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-5%);
    .jumbo {
      display: flex;
      justify-content: center;
      flex-direction: column;
      p {
        font-size: 1.5rem;
        font-weight: lighter;
        padding-top: 15%;
        font-style: italic;
      }
      a.button {
        border: 4px solid rgba(255, 255, 255, 0.7);
        color: rgba(255, 255, 255, 0.7);
        background: none;
        padding: 1rem;
        cursor: pointer;
        text-align: center;
        transition: all 0.3s;
        font-size: 1.5rem;
        &:hover,
        &:focus,
        &:active {
          border: 4px solid white;
          color: white;
          box-shadow: inset 8.5em 5px 5px 5px rgba(255, 255, 255, 0.3);
        }
        &:link,
        &:visited {
          text-decoration: none;
          text-transform: none;
          color: inherit;
        }
      }
    }
  }
}
@media screen and (max-width: 980px) {
  .intro {
    background-attachment: unset;
    background-size: cover;
  }
}
@media screen and (max-width: 680px) {
  .intro {
    padding: 30% 0;
    h1 {
      font-size: 4rem;
    }
  }
}
</style>