import { createStore } from "vuex";
import { setLocale } from "../main";

export default createStore({
  state: {
    locale: { lang: "en", country: "England", flag: "en" },
  },
  mutations: {
    setLang(state, payload) {
      state.locale = payload;
      setLocale(payload);
    },
  },
  actions: {
    setLang(context, payload) {
      context.commit("setLang", payload);
    },
  },
  getters: {
    getLocale(state) {
      return state.locale;
    },
  },
  modules: {},
});
