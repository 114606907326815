import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

import { createI18n } from "vue-i18n";

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = createI18n({
  locale: store.getters.getLocale.flag,
  fallbackLocale: store.getters.getLocale.flag,
  messages: loadLocaleMessages(),
});

createApp(App).use(VueViewer).use(store).use(router).use(i18n).mount("#app");

export const setLocale = (lang) => {
  i18n.global.locale = lang.lang;
};
